import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGem,
  // faCode,
  // faChartNetwork,
} from "@fortawesome/pro-regular-svg-icons";
import { faReact } from "@fortawesome/free-brands-svg-icons";

import Layout from "../components/layout";
import LogoCloud from "../components/LogoCloud";
import SEO from "../components/seo";
import Card from "../components/card";
import LinkButton from "../components/LinkButton";

const IndexPage = () => {
  return (
    <Layout>
      {(linkClicked, cardVisible) => (
        <>
          <SEO keywords={[]} title="Services" />

          <Card visible={cardVisible}>
            <ul>
              <li className="flex items-center justify-between">
                <div className="flex-shrink-0">
                  <FontAwesomeIcon icon={faGem} className="text-magenta-900" />
                </div>
                <p className="ml-3 text-base leading-6 text-gray-700 text-left flex-1">
                  Ruby on Rails Consulting and Development
                </p>
                <LinkButton url="/ruby-on-rails" onClick={linkClicked}>
                  Learn more
                </LinkButton>
              </li>
              <li className="mt-4 flex items-center justify-between">
                <div className="flex-shrink-0">
                  <FontAwesomeIcon
                    icon={faReact}
                    className="text-magenta-900"
                  />
                </div>
                <div className="text-left flex-1">
                  <p className="ml-3 text-base leading-6 text-gray-700 text-left">
                    Custom Distributed Multimedia Applications
                  </p>
                  <p className="ml-3 text-sm leading-6 text-gray-500 text-left">
                    (React / React Native / React 360 / Electron JS)
                  </p>
                </div>
                <div className="rounded-md shadow">
                  <LinkButton
                    url="/custom-distributed-multimedia-apps"
                    onClick={linkClicked}
                  >
                    Learn More
                  </LinkButton>
                </div>
              </li>
              {/* <li className="mt-4 flex items-center justify-between"> */}
              {/*   <div className="flex-shrink-0"> */}
              {/*     <FontAwesomeIcon icon={faCode} className="text-magenta-900" /> */}
              {/*   </div> */}
              {/*   <p className="ml-3 text-base leading-6 text-gray-700 text-left flex-1"> */}
              {/*     Progressive Web Applications */}
              {/*   </p> */}
              {/*   <div className="rounded-md shadow"> */}
              {/*     <LinkButton url="#">Learn More</LinkButton> */}
              {/*   </div> */}
              {/* </li> */}
              {/* <li className="mt-4 flex items-center justify-between"> */}
              {/*   <div className="flex-shrink-0"> */}
              {/*     <FontAwesomeIcon */}
              {/*       icon={faChartNetwork} */}
              {/*       className="text-magenta-900" */}
              {/*     /> */}
              {/*   </div> */}
              {/*   <div className="text-left flex-1"> */}
              {/*     <p className="ml-3 text-base leading-6 text-gray-700 text-left"> */}
              {/*       Reactive IoT Based Applications */}
              {/*     </p> */}
              {/*     <p className="ml-3 text-sm leading-6 text-gray-500 text-left"> */}
              {/*       (Elixir / Phoenix / Nerves) */}
              {/*     </p> */}
              {/*   </div> */}
              {/*   <div className="rounded-md shadow"> */}
              {/*     <LinkButton url="#">Learn More</LinkButton> */}
              {/*   </div> */}
              {/* </li> */}
            </ul>
          </Card>
          <LogoCloud
            logos={[
              {
                name: "bullet-train",
                url: "https://bullettrain.co/",
                type: "png",
              },
              {
                name: "code-and-co",
                url: "https://www.codeandco.com/",
                type: "svg",
              },
              {
                name: "weavs",
                url: "https://www.weavs.io",
                type: "svg",
              },
              {
                name: "kreativfarm",
                url: "http://kreativ.farm/",
                type: "svg",
              },
              {
                name: "inter-pool",
                url: "https://www.inter-pool.at",
                type: "svg",
              },
            ]}
          />
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
            {/* <div className="rounded-md shadow"> */}
            {/*   <a */}
            {/*     href="#" */}
            {/*     className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-magenta hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10" */}
            {/*   > */}
            {/*     Get started */}
            {/*   </a> */}
            {/* </div> */}
            {/* <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3"> */}
            {/*   <a */}
            {/*     href="#" */}
            {/*     className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-magenta bg-white hover:text-indigo-500 focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10" */}
            {/*   > */}
            {/*     Live demo */}
            {/*   </a> */}
            {/* </div> */}
          </div>
        </>
      )}
    </Layout>
  );
};

export default IndexPage;
